<template>
    <div class="container" style="position:relative">
        <v-btn v-if="lang==='en'" :color="ada.darkGrey" text @click="toggleLanguage" class="languageButton ma-2">
            <v-img src="@/assets/germany.png" width="25" class="mr-2"></v-img>
            German
        </v-btn>
        <v-btn v-else :color="ada.darkGrey" text @click="toggleLanguage" class="languageButton ma-4">
            <v-img src="@/assets/united-kingdom.png" width="25" class="mr-2"></v-img>
            English
        </v-btn>
        <TooltipButton v-if="role === 'admin'" tooltip="Log vendor entry from the Global Vendor List (GVL)"
                       @click="logGVLVendor" class="ma-2"
                       style="width:min-content; position: absolute; right: 150px; top:0; z-index: 2"
                       :color="ada.blue">*Log GVL Vendor
        </TooltipButton>
        <div class="sectionHeadline text-h6" :class="{'mb-4': role === 'admin'}" style="position: relative">TCF v2.2
            support
            <div v-if="role === 'admin' && gvlUpdatedAt && gvlUpdatedAt.indexOf('()') === -1"
                 style="font-size: 12px;position: absolute; top:22px;">*IAB's GVL last updated at: {{ gvlUpdatedAt }}
                <TooltipIcon @click="fetchGVL" :custom-style="{cursor:'pointer', position: 'relative', top: '-2px'}"
                             tooltip="refresh" :color="ada.pink" small>mdi-refresh
                </TooltipIcon>
            </div>
        </div>
        <v-form v-model="valid" ref="form">
            <v-row no-gutters>
                <v-col cols="12">
                    <v-radio-group v-model="data.tcf_status" row @change="statusChange"
                                   :disabled="$store.state.readOnly === 1 || !allowEdit">
                        <v-radio :color="ada.pink" class="mr-8"
                                 label="Registered"
                                 value="registered"
                        ></v-radio>
                        <v-radio :color="ada.pink"
                                 label="Registration planned"
                                 value="planned"
                        ></v-radio>
                    </v-radio-group>
                </v-col>
                <v-slide-y-transition hide-on-leave>
                    <v-col v-show="data.tcf_status === 'registered'" cols="12">
                        <Input label="TCF ID" v-model.number="data.tcf_id" :rules="rules.tcf_id"
                               @blur="handleChange" :disabled="$store.state.readOnly === 1 || !allowEdit"></Input>
                    </v-col>
                </v-slide-y-transition>

                <v-col cols="12">
                    <p :style="{color: data.tcf_status === 'planned' ? 'darkorange' : ''}">
                        Please note, that a registration for TCF v2.2 is an absolute precondition for working with
                        Ad Alliance.</p>
                </v-col>
            </v-row>
        </v-form>

        <v-alert
            v-if="purposes_valid === false"
            text
            prominent
            type="error"
            icon="mdi-alert-octagon"
        >
            {{ iabWarning[lang] }}
        </v-alert>
        <v-alert
            v-else-if="role === 'admin' && purposes_valid === true"
            text
            color="green darken-1"
            icon="mdi-comment-check"
        >
            {{ iabSuccess[lang] }}
        </v-alert>
        <div v-for="(type, i) in types" :key="i" class="my-6 mb-10 borderTop">
            <v-row class="pt-6">
                <v-col cols="7" class="font-weight-bold">
                    {{
                        type === null ? 'Data Processing Purposes' :
                            (type === 'special' ? 'Special Purposes' :
                                (type === 'feature' ? 'Features' :
                                    (type === 'special_feature' ? 'Special Features' : '')))
                    }} (based on TCF)
                </v-col>
                <v-col cols="3" class="pl-6 font-weight-bold">
                    {{ type !== 'special_feature' ? 'Legitimate interest' : '' }}
                </v-col>
                <v-col cols="2" class="font-weight-bold" v-if="type !== 'special'">
                    Consent
                </v-col>
            </v-row>

            <v-row v-for="purpose in purposes.filter(x=>x.type === type)" :key="purpose.id" class="align-center">
                <v-col cols="8">{{ purpose.name }}</v-col>
                <v-col cols="2">
                    <v-checkbox
                        v-if="typeof gvlPurposesMap[purpose.id].hide_legitimate_interest === 'undefined' || typeof gvlPurposesMap[purpose.id].hide_legitimate_interest === false"
                        class="ma-0" hide-details v-model="purpose.pivot.legitimate_interest"
                        :color="ada.pink" :true-value="1" :false-value="0"
                        :disabled="$store.state.readOnly === 1 || !allowEdit"
                    ></v-checkbox>
                </v-col>
                <v-col cols="2">
                    <v-checkbox
                        v-if="typeof gvlPurposesMap[purpose.id].hide_consent === 'undefined' || typeof gvlPurposesMap[purpose.id].hide_consent === false"
                        class="ma-0" hide-details v-model="purpose.pivot.consent" :color="ada.pink"
                        :true-value="1" :false-value="0"
                        :disabled="$store.state.readOnly === 1 || !allowEdit"
                    ></v-checkbox>
                </v-col>
            </v-row>
        </div>
        <div v-if="role === 'admin'">*Nur für Admins sichtbar</div>
    </div>
</template>

<script>
import ada from '@/scss/variables.scss'
import Input from "../../../components/Inputs/Input";
import TooltipIcon from "../../../components/buttons/TooltipIcon";
import TooltipButton from "../../../components/buttons/TooltipButton";

export default {
    name: "TCF",
    props: ['data', 'tabIndex', 'previousIndex', 'tabs', 'allowEdit'],
    components: {TooltipButton, TooltipIcon, Input},
    data: () => ({
        tcf_status: 'registered',
        tcf_id: null,
        purposes: [],
        valid: null,
        ignore: false,
        gvlUpdatedAt: null,
        gvlPurposesMap: {
            //key = DB purpose id: {id = iab id, key = gvl key}
            1: {id: 1, key: 'purposes', hide_legitimate_interest: true},
            2: {id: 2, key: 'purposes'},
            3: {id: 3, key: 'purposes', hide_legitimate_interest: true},
            4: {id: 4, key: 'purposes', hide_legitimate_interest: true},
            5: {id: 5, key: 'purposes', hide_legitimate_interest: true},
            6: {id: 6, key: 'purposes', hide_legitimate_interest: true},
            7: {id: 7, key: 'purposes'},
            8: {id: 8, key: 'purposes'},
            9: {id: 9, key: 'purposes'},
            10: {id: 10, key: 'purposes'},
            18: {id: 11, key: 'purposes'},
            11: {id: 1, key: 'specialPurposes', hide_consent: true},
            12: {id: 2, key: 'specialPurposes', hide_consent: true},
            19: {id: 3, key: 'specialPurposes', hide_consent: true},
            13: {id: 1, key: 'features'},
            14: {id: 2, key: 'features'},
            15: {id: 3, key: 'features'},
            16: {id: 1, key: 'specialFeatures', hide_legitimate_interest: true},
            17: {id: 2, key: 'specialFeatures', hide_legitimate_interest: true}
        },
        iabWarning: {
            'de': 'Die angegebenen Daten stimmen nicht mit der IAB Global Vendor List überein. Bitte passen Sie diese entsprechend an. Falls Sie einen Purpose als flexibel registriert haben wählen Sie, wenn möglich, bitte beide Checkboxen (Consent und Legitimate Interest) aus.',
            'en': 'It was noticed that the details of the TCF registration differ in relation to the TCF Global Vendor List. Please check and adjust your details. If you registered a purpose with the TCF as flexible, please check both boxes for Consent and Legitimate interest if possible.'
        },
        iabSuccess: {
            'de': '*Die angegebenen Daten stimmen mit der IAB Global Vendor List überein.',
            'en': '*No differences found in the details of the TCF registration and the TCF Global Vendor List.'
        },
        purposes_valid: null,
        loadingGVL: false,
        emitOnce: false,
        ada
    }),
    mounted() {
        if (this.purposes_store.length === 0) {
            this.fetchPurposes()
        } else {
            this.initPurposes()
        }
        if (this.gvl === null) {
            this.getGVL();
        }
        this.getGVLTime();
        this.$emit('mounted', this);
    },
    computed: {
        purposes_store() {
            return this.$store.state.details.purposes;
        },
        types() {
            let ret = [];
            if (this.purposes_store && this.purposes_store.length !== 0) {
                let types = this.purposes_store.map(x => x.type);
                ret = [...new Set(types)];
            }
            return ret;
        },
        rules() {
            return {
                tcf_id: [() => {
                    return this.data.tcf_status === 'registered' && (this.data.tcf_id === null || this.data.tcf_id === '') ? 'TCF Vendor ID is required' : true;
                }]
            }
        },
        hasChanges() {
            return this.$store.state.details.components.purposes.hasChanges;
        },
        role() {
            return this.$store.state.users.current ? this.$store.state.users.current.role : null;
        },
        allReqFieldsNotFilled() {
            return (
                this.data.tcf_status === 'required' &&
                this.data.tcf_id === null
            )
        },
        lang() {
            return this.$store.state.details.lang;
        },
        gvl() {
            return this.$store.state.details.gvl;
        }
    },
    methods: {
        init() {
            if (this.data) {
                this.data.purposes.forEach((purpose) => {
                    //purpose example { "id": 1, "name": "Store...", "type": null, "pivot": { "legitimate_interest": 1, "consent": 1 }
                    let index = this.purposes.findIndex(x => x.id === purpose.id);
                    if (index !== -1) {
                        this.purposes[index].pivot.legitimate_interest = purpose.pivot.legitimate_interest;
                        this.purposes[index].pivot.consent = purpose.pivot.consent;
                    }
                });
                this.ignore = false;
                this.purposes_valid = null;
                this.compareWithGVL();
            }
        },
        initPurposes() {
            if (this.purposes_store && this.purposes_store.length !== 0) {
                this.ignore = true;
                this.purposes = [];
                this.purposes_store.forEach((purpose) => {
                    this.purposes.push({
                        id: purpose.id,
                        name: purpose.name,
                        type: purpose.type,
                        pivot: {
                            legitimate_interest: 0,
                            consent: 0
                        }
                    })
                })
                this.init();
            }
        },
        async fetchPurposes() {
            if (!this.$store.state.details.fetching.purposes) {
                await this.$store.dispatch('details/fetchPurposes');
            }
        },
        statusChange() {
            this.$refs.form.resetValidation();
            this.handleChange();
        },
        handleChange() {
            let role = this.role === 'agency_group' ? 'agency' : this.role;
            let og_data = this.role === 'admin' || this.role === 'user' ?
                this.$store.state.agency_vendor.selectedData.details :
                this.$store.state.details.data[role].details;
            if (og_data === null) og_data = JSON.parse(JSON.stringify(this.$store.state.details.details_template));
            let changed = false;
            if (og_data.purposes.length !== 0 && og_data.purposes.length === this.data.purposes.length) {
                for (let i = 0; i < og_data.purposes.length; i++) {
                    if (og_data.purposes[i].id !== this.data.purposes[i].id ||
                        og_data.purposes[i].pivot.legitimate_interest !== this.data.purposes[i].pivot.legitimate_interest ||
                        og_data.purposes[i].pivot.consent !== this.data.purposes[i].pivot.consent) {
                        changed = true;
                        break;
                    }
                }
            }
            if (og_data.tcf_status !== this.data.tcf_status ||
                og_data.tcf_id !== this.data.tcf_id ||
                og_data.purposes.length !== this.data.purposes.length ||
                changed
            ) {
                if (!this.hasChanges)
                    this.$store.commit('details/updateHasChanges', {section: 'purposes', value: true});
            } else {
                if (this.hasChanges)
                    this.$store.commit('details/updateHasChanges', {section: 'purposes', value: false});
            }
        },
        logGVLVendor() {
            this.$root.$emit('snackbar', 'GVL Vendor successfully logged to console (F12).')
            console.log('GVL ID: ' + this.data.tcf_id, this.gvl.vendors[this.data.tcf_id]);
        },
        async getGVL() {
            if (!this.loadingGVL) {
                this.loadingGVL = true;
                await this.$store.dispatch('details/getGVL');
                this.loadingGVL = false;
                await this.compareWithGVL();
            }
        },
        async fetchGVL() {
            await this.$store.dispatch('details/fetchGVL');
            await this.getGVLTime();
        },
        async getGVLTime() {
            this.gvlUpdatedAt = await this.$store.dispatch('details/getGVLLastModified');
        },
        async compareWithGVL() {
            if (this.data.tcf_id === null || this.gvl && typeof this.gvl.vendors[this.data.tcf_id] === "undefined") {
                this.purposes_valid = true;
                this.$emit('updateBadge', this.tabIndex);
                return;
            }
            if (this.gvl) {
                console.log(this.gvl.vendors[this.data.tcf_id]);
                let valid = true;
                let ret = [
                    {   //purposes = purposes ONLY consent
                        //remove ids that also exist in flexiblePurposes (cause flex = both)
                        gvl: this.gvl.vendors[this.data.tcf_id].purposes.filter(x => !this.gvl.vendors[this.data.tcf_id].flexiblePurposes.includes(x)),
                        local: this.data.purposes.filter(x => x.type === null && x.pivot.consent === 1 && x.pivot.legitimate_interest === 0).map(x => this.gvlPurposesMap[x.id].id)
                    },
                    {   //legIntPurposes = purposes legInt required
                        gvl: this.gvl.vendors[this.data.tcf_id].legIntPurposes.filter(x => !this.gvl.vendors[this.data.tcf_id].flexiblePurposes.includes(x)),
                        local: this.data.purposes.filter(x => x.type === null && x.pivot.legitimate_interest === 1 && x.pivot.consent === 0).map(x => this.gvlPurposesMap[x.id].id)
                    },
                    {   //flexiblePurposes = both options required
                        gvl: this.gvl.vendors[this.data.tcf_id].flexiblePurposes,
                        local: this.data.purposes.filter(x => x.type === null && x.pivot.legitimate_interest === 1 && x.pivot.consent === 1).map(x => this.gvlPurposesMap[x.id].id)
                    },
                    {   //specialPurposes = legInt required
                        gvl: this.gvl.vendors[this.data.tcf_id].specialPurposes,
                        local: this.data.purposes.filter(x => x.type === 'special' && x.pivot.legitimate_interest === 1).map(x => this.gvlPurposesMap[x.id].id)
                    },
                    {   //features = one of both required
                        gvl: this.gvl.vendors[this.data.tcf_id].features,
                        local: this.data.purposes.filter(x => x.type === 'feature' && (x.pivot.consent === 1 || x.pivot.legitimate_interest === 1)).map(x => this.gvlPurposesMap[x.id].id)
                    },
                    {   //specialFeatures = consent required
                        gvl: this.gvl.vendors[this.data.tcf_id].specialFeatures,
                        local: this.data.purposes.filter(x => x.type === 'special_feature' && x.pivot.consent === 1).map(x => this.gvlPurposesMap[x.id].id)
                    }
                ];
                for (let i = 0; i < ret.length; i++) {
                    let isEqual = ret[i].gvl.length === ret[i].local.length &&
                        ret[i].gvl.every(function (x) {
                            return ret[i].local.includes(x);
                        });
                    if (!isEqual) {
                        valid = false;
                        break;
                    }
                }
                this.purposes_valid = valid;
                this.$emit('updateBadge', this.tabIndex);
            } else {
                if (!this.loadingGVL) {
                    await this.getGVL();
                    await this.compareWithGVL();
                }
            }
        },
        toggleLanguage() {
            this.$store.commit('details/setLang', this.lang === 'en' ? 'de' : 'en');
        },
    },
    watch: {
        purposes: {
            handler(v) {
                if (!this.ignore && this.data.purposes) {
                    this.data.purposes = v.filter(x => x.pivot.legitimate_interest === 1 || x.pivot.consent === 1);
                    this.handleChange();
                } else {
                    this.ignore = false;
                }
            },
            deep: true
        },
        data() {
            this.initPurposes()
            if (!this.$store.state.details.isRegistration) {
                setTimeout(() => {
                    this.$emit('validate', 'TCF');
                }, 500);
            }
        },
        valid() {
            this.$emit('validate', 'TCF');
        },
        purposes_store() {
            this.initPurposes()
        }
    },
}
</script>

<style lang="scss" scoped>
.borderTop {
    border-top: 1px solid $ada_lightGrey;
}

.languageButton {
    position: absolute;
    top: 0;
    right: 0;
}

::v-deep .mdi-loading::before {
    animation: rotation 0.7s infinite linear;
    -webkit-animation: rotation 0.7s infinite linear;
}

@-webkit-keyframes rotation {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(359deg);
    }
}
</style>